<template>
    <div>
        <base-header class="pb-6">
            <b-row class="align-items-center py-4">
                <b-col lg="6" cols="7">
                    <h6 class="h2 text-white d-inline-block mb-0">Calculator</h6>
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                    <route-breadcrumb/>
                    </nav>
                </b-col>
            </b-row>
        </base-header>
  
        <b-container fluid class="mt--6">
            <card>
                <h3 slot="header">Invinity Calculator</h3>
                <form>
                    <!-- Input groups with icon -->
                    <b-row>
                        <base-input label="Calculator Type: ">
                            <el-select v-model="selects.simple" filterable  placeholder="Select Calculator Type">
                                <el-option v-for="option in selectOptions" :key="option.key" :label="option.name" :value="option.key">
                                </el-option>
                            </el-select>
                        </base-input>
                        <b-col md="3">
                            <base-input type="number" label="Credit Loan Amount *" name="Credit Loan Amount" rules="required" placeholder="Loan Amount" v-model="credit_loan_amount"></base-input>
                        </b-col>
                        <b-col md="3">
                            <base-input type="number" label="Drawdown Amount" name="Drawdown Amount" placeholder="Drawdown Amount" v-model="release_amount"></base-input>
                        </b-col>
                        <b-col md="3">
                            <base-input type="number" label="Tenure *" name="Tenure" rules="required" placeholder="Tenure" v-model="tenure"></base-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <!-- <b-col md="3">
                            <base-input label="Tenure Type *">
                                <b-form-radio-group v-model="tenure_type_code">
                                    <b-row>
                                        <b-form-radio
                                            value="year"
                                            name="Year"
                                            class="mb-3">
                                            Year
                                        </b-form-radio>
                                        <b-form-radio
                                            value="month"
                                            name="Month"
                                            class="mb-3">
                                            Month
                                        </b-form-radio>
                                    </b-row>
                                </b-form-radio-group>
                            </base-input>
                        </b-col> -->
                        <b-col md="3">
                            <base-input type="number" label="Insurance *" name="Insurance" rules="required" placeholder="Insurance" v-show="selects.simple == 'INSURANCE'" v-model="insurance"></base-input>
                        </b-col>
                        <b-col class="text-right">
                            <base-button type="primary" @click="createData()">Submit</base-button>
                            <base-button type="primary" @click="clear()">Clear</base-button>
                        </b-col>
                    </b-row>
                </form>
                <b-col md="3">
                    <h5 class="mt-4 text-danger">
                        <i>Column with * must fill in with value.</i>
                    </h5>
                </b-col>
                <br>
                <b-col>
                    <b-row align-v="center" class="justify-content-center">
                    <table width="80%">
                        <tr>
                            <td width="40%">Credit Loan Amount</td>
                            <td></td>
                            <td width="40%">{{ tableData.credit_loan_amount }}</td>
                        </tr>
                        <tr>
                            <td width="40%">Tenure ({{ tenure_type_code }})</td>
                            <td></td>
                            <td width="60%">{{ tableData.tenure }}</td>
                        </tr>
                        <tr>
                            <td width="40%">Interest (18% p.a.)</td>
                            <td></td>
                            <td width="60%">{{ tableData.interest_rate_percent }}</td>
                        </tr>
                        <tr>
                            <td width="40%">Instalment</td>
                            <td></td>
                            <td width="60%">{{ tableData.instalment_amount }}</td>
                        </tr>
                        <tr>
                            <td width="40%">Total Interest</td>
                            <td></td>
                            <td width="60%">{{ tableData.total_interest_amount }}</td>
                        </tr>
                        <br>
                        <tr v-if="selects.simple != 'SHORT_TERM'">
                            <td width="40%">Legal Fees</td>
                            <td></td>
                            <td width="60%">{{ tableData.legal_fees_amount }}</td>
                        </tr>
                        <tr>
                            <td width="40%">Legal Fees - 6% SST</td>
                            <td>{{ tableData.legal_fees_sst_percent }}</td>
                            <td width="60%">{{ tableData.legal_fees_sst_amount }}</td>
                        </tr>
                        <tr>
                            <td width="40%">Stamp Duty</td>
                            <td>{{ tableData.stamp_duty_percent }}</td>
                            <td width="60%">{{ tableData.stamp_duty_amount }}</td>
                        </tr>
                        <tr>
                            <td width="40%">Collaboration Fees</td>
                            <td>{{ tableData.admin_fees_percent }}</td>
                            <td width="60%">{{ tableData.admin_fees_amount }}</td>
                        </tr>
                        <tr>
                            <td width="40%">Collaboration Fees - 6% SST</td>
                            <td>{{ tableData.admin_fees_sst_percent }}</td>
                            <td width="60%">{{ tableData.admin_fees_sst_amount }}</td>
                        </tr>
                        <tr v-if="selects.simple == 'STANDARD'">
                            <td width="40%">Security Deposit</td>
                            <td></td>
                            <td width="60%">{{ tableData.advance_instalment_amount }}</td>
                        </tr>
                        <tr v-if="selects.simple == 'INSURANCE'">
                            <td width="40%">Insurance </td>
                            <td></td>
                            <td width="60%">{{ tableData.insurance }}</td>
                        </tr>
                        <br>
                        <tr>
                            <td width="40%">Disbursement Amount</td>
                            <td></td>
                            <td width="60%">{{ tableData.disbursement_amount }}</td>
                        </tr>
                        <tr>
                            <td width="40%">Drawdown Amount</td>
                            <td></td>
                            <td width="60%">{{ tableData.release_amount }}</td>
                        </tr>
                        <tr>
                            <td width="40%">Balance</td>
                            <td></td>
                            <td width="60%">{{ tableData.balance_disbursement_amount }}</td>
                        </tr>
                    </table>
                    </b-row>
                </b-col>
            </card>
        </b-container>
    </div>
  </template>
  
  <script>
    import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option, Button, Badge, Tag } from 'element-ui'
    import axios from 'axios'
    import {apiDomain} from '@/assets/js/config.js'
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import swal from 'sweetalert2';
    import 'sweetalert2/dist/sweetalert2.css';
    import { BasePagination } from '@/components';
    import clientPaginationMixin from '@/assets/js/clientPaginationMixin'
    import debounce from 'lodash.debounce';
  
    export default {
      mixins: [ErrorFunctionMixin,clientPaginationMixin],
      components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Button.name]: Button,
        [Badge.name]: Badge,
        [Tag.name]: Tag,
      },
      data() {
        return {
          tableData: [],
          credit_loan_amount: null,
          release_amount: null,
          tenure: null,
          insurance: null,
          selects: {
            simple: 'STANDARD'
          },
          tenure_type_code: 'year',
          selectOptions: [
            {
                key: 'STANDARD',
                name: 'Standard Credit Loan',
            },
            {
                key: 'INSURANCE',
                name: 'Insurance Credit Loan',
            },
            {
                key: 'FACTORY',
                name: 'Factory Credit Loan',
            },
            {
                key: 'SHORT_TERM',
                name: 'Short Term Credit Loan',
            },
          ],
        };
      },
      methods: {
        clear(){
          this.credit_loan_amount = null;
          this.release_amount = null;
          this.tenure = null;
          this.insurance = null;
          this.tableData = [];
        },
        createData(){
            if(this.selects.simple == 'INSURANCE')
            {
                if(!this.insurance)
                {
                    return this.redErrorNotify( '<b>Invalid Input : Insurance is Mandatory. </b>')
                }
            }

            if(!this.credit_loan_amount || !this.tenure)
            {
                if(!this.credit_loan_amount && !this.tenure)
                {
                    this.redErrorNotify( '<b>Invalid Input : <br>Credit Loan Amount & Tenure is Mandatory. </b>')
                }
                else if(!this.credit_loan_amount){
                    this.redErrorNotify( '<b>Invalid Input : Credit Loan Amount is Mandatory. </b>')
                }
                else if(!this.tenure)
                {
                    this.redErrorNotify( '<b>Invalid Input : Tenure is Mandatory. <br>Tenure Year must between 1-5 years. </b>')
                }
            }
            else
            {
                if(this.selects.simple == 'STANDARD' || this.selects.simple == 'INSURANCE')
                {
                    this.tenure_type_code = 'year';
                }
                else
                {
                    this.tenure_type_code = 'month';
                }

                const postData = {
                    credit_loan_amount: this.credit_loan_amount,
                    release_amount: this.release_amount,
                    tenure: this.tenure,
                    tenure_type_code: this.tenure_type_code,
                    insurance: this.insurance,
                    invinity_calculator_type_code: this.selects.simple,
                }
    
                axios.post(apiDomain + 'calculator/invinity_calculator', postData, { headers: {"access-control-allow-origin": "*"} })
                .then(response => {
                this.tableData = response.data,
                this.$notify({
                    message:
                    '<b>Data Successfully Created.',
                    timeout: 5000,
                    icon: 'ni ni-bell-55',
                    type: 'default',
                });
                })
                .catch ( error => {
                    this.errorFunction(error, "Error In Calculation") 
                });
            }
        },
  
      }
    }
  </script>
  <style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }
  table,th,td {
      border: 1px solid black;
  }
  th, td {
      padding: 3px;
  }
  .row {
      margin-left:-5px;
      margin-right:-5px;
  }
  .column {
      float: left;
      width: 20%;
      padding: 5px;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 50%;
    /* border: 1px solid #ddd; */
    align-content: center;
  }
  </style>