<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Calculator</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <card>
        <h3 slot="header">Basket Analyze</h3>
        <form>
          <!-- Input groups with icon -->
          <b-row>
            <b-col md="3">
              <base-input type="text" label="Report Id" name="Report Id" placeholder="Report Id" v-model="report_id"></base-input>
            </b-col>
            <b-col md="3">
              <base-input type="text" label="Customer Name *" rules="required" name="Customer Name" placeholder="Customer Name" v-model="customer_name"></base-input>
            </b-col>
            <b-col md="3">
              <base-input type="text" label="XML File Name" name="XML File Name" placeholder="XML File Name" v-model="xml_file_name"></base-input>
            </b-col>
            <b-col>
              <h5 class="text-danger">
                <i>Column with * must fill in with value.</i>
              </h5>
            </b-col>
            
          </b-row>
          &nbsp;&nbsp;<base-button type="primary" @click="createData()">Submit</base-button>
          <base-button type="primary" @click="clear()">Clear</base-button>
        </form>
        <br>
        <b-row>
          <h3>Paid Off Month : RM {{paid_off_month_result}}</h3>
        </b-row>
        <b-col>
          <b-row align-v="center" class="justify-content-center">
            <table>
                <tr>
                  <th>Period</th>
                  <th>Accumulated Month</th>
                  <th>Interest charged (RM)</th>
                  <th>Principal (RM)</th>
                  <th>Instalment (RM)</th>
                  <th>Balance (RM)</th>
                </tr>
                <tr v-for="(data, key) in tableData" :key="key">
                  <template v-if="key != 'total' && key != 'paid_off_month'">
                    <td>{{key}}</td>
                    <td>{{data["Month"]}}</td>
                    <td>{{data["Interest Charged"]}}</td>
                    <td>{{data["Principal"]}}</td>
                    <td>{{data["Instalment"]}}</td>
                    <td>{{data["Balance"]}}</td>
                  </template>
                </tr>
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td>{{total_interest_charged}}</td>
                  <td>{{total_principal}}</td>
                  <td>{{total_instalment}}</td>
                </tr>
            </table>
          </b-row>
        </b-col>
      </card>
      
      
    </b-container>
  </div>
</template>

<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import { BasePagination } from '@/components';
  import clientPaginationMixin from '@/assets/js/clientPaginationMixin'
  import debounce from 'lodash.debounce';

  export default {
    mixins: [ErrorFunctionMixin,clientPaginationMixin],
    components: {

    },
    data() {
      return {
        tableData: [],
        report_id: null,
        customer_name: null,
        xml_file_name: null,
        debouncedHandler: '',
      };
    },
    methods: {
      clear(){
        this.report_id = null;
        this.customer_name = null;
        this.xml_file_name = null;
        this.tableData = [];
      },
      createData(){
        if(!this.customer_name)
        {
          this.redErrorNotify( '<b>Invalid Input : Customer Name is Mandatory. </b>')
        }
        else
        {
          const postData = {
            report_id: this.report_id,
            cust_name: this.customer_name,
            xml_file_name: this.xml_file_name,
          }

          axios.post(apiDomain + 'calculator/basket_analyze', postData, { headers: {"access-control-allow-origin": "*"} })
          .then(response => {
            this.tableData = response.data,
            this.total_interest_charged = response.data.total.total_interest_charged,
            this.total_principal = response.data.total.total_principal,
            this.total_instalment = response.data.total.total_instalment,
            this.paid_off_month_result = response.data.paid_off_month,
            this.$notify({
              message:
                '<b>Data Successfully Created.',
              timeout: 5000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          })
          .catch ( error => {
              // this.redErrorNotify( error.response.data);
              this.errorFunction(error, "Error In Calculation") 
          });
        }
        
        // const str = JSON.stringify(this.tableData, null, 2);
        // console.log(str);
      },

    }
  }
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
table,th,td {
    border: 1px solid black;
}
th, td {
    padding: 10px;
}
.row {
    margin-left:-5px;
    margin-right:-5px;
}
.column {
    float: left;
    width: 20%;
    padding: 5px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 50%;
  /* border: 1px solid #ddd; */
  align-content: center;
}
</style>