import { render, staticRenderFns } from "./PropertyIncomeEligibleCalculator.vue?vue&type=template&id=8be4ea60&scoped=true&"
import script from "./PropertyIncomeEligibleCalculator.vue?vue&type=script&lang=js&"
export * from "./PropertyIncomeEligibleCalculator.vue?vue&type=script&lang=js&"
import style0 from "./PropertyIncomeEligibleCalculator.vue?vue&type=style&index=0&id=8be4ea60&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8be4ea60",
  null
  
)

export default component.exports