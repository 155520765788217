<template>
    <div>
        <base-header class="pb-6">
            <b-row class="align-items-center py-4">
                <b-col lg="6" cols="7">
                    <h6 class="h2 text-white d-inline-block mb-0">Calculator</h6>
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                    <route-breadcrumb/>
                    </nav>
                </b-col>
            </b-row>
        </base-header>
  
        <b-container fluid class="mt--6">
            <card>
                <h3 slot="header">Hire Purchase Income Eligible Calculator</h3>
                <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                    <form @submit.prevent="handleSubmit(createData)" class="needs-validation">
                        <!-- Input groups with icon -->
                        <b-row>
                            <b-col md="2">
                                <base-input type="number" label="Age *" name="Age" rules="required" placeholder="Age" v-model="age"></base-input>
                            </b-col>
                            <b-col md="2">
                                <!-- <base-input type="text" label="Bank Commitment Amount *" name="Bank Commitment Amount" rules="required" placeholder="Bank Commitment Amount" v-model="bank_commitment"></base-input> -->
                                <b-form-group label="Bank Commitment Amount *">
                                    <CurrencyInput
                                        v-model="bank_commitment" required
                                        :options="{ currency: 'MYR', currencyDisplay: 'hidden', autoDecimalDigits: true }"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <!-- <base-input type="text" label="Car Price *" name="Car Price" placeholder="Car Price" rules="required" v-model="hire_purchase_amount"></base-input> -->
                                <b-form-group label="Car Price *">
                                    <CurrencyInput
                                        v-model="hire_purchase_amount" required
                                        :options="{ currency: 'MYR', currencyDisplay: 'hidden', autoDecimalDigits: true }"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <base-input type="text" label="Loan Margin *" name="Loan Margin" placeholder="Loan Margin" rules="required" v-model="loan_margin"></base-input>
                            </b-col>
                            <b-col md="2">
                                <base-input type="text" label="Tenure Year *" name="Tenure Year" placeholder="Tenure Year" rules="required" v-model="tenure_year"></base-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="2">
                            <b-form-group label="Hire Purchase Type">
                                <el-radio-group v-model="hire_purchase_type">
                                    <el-radio :label="'fixed'">Fixed</el-radio>
                                    <el-radio :label="'second'">Second-hand</el-radio>
                                </el-radio-group>
                            </b-form-group>
                        </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col class="text-right">
                                <base-button type="primary" native-type="submit">Submit</base-button>
                                <base-button type="primary" @click="clear()">Clear</base-button>
                            </b-col>
                        </b-row>
                    </form>
                </validation-observer>
                <b-col md="3">
                    <h5 class="mt-4 text-danger">
                        <i>Column with * must fill in with value.</i>
                    </h5>
                </b-col>
                <br>
                <b-col cols="12" v-if="Object.keys(tableData).length > 0">
                   <b-table-simple responsive bordered class="calculator-table">
                        <b-thead>
                            <b-tr>
                                <b-th class="bg-dark text-white">Bank Name</b-th>
                                <b-th class="bg-dark text-white" v-for="(bank, index) in tableData" :key="index">{{ bank.bank_name }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th class="bg-dark text-white">Vehicle Price</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">RM {{ bank.hire_purchase_amount }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Target MOF (Loan Margin)</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">{{ bank.loan_margin }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Target Loan Amount</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">RM {{ bank.loan_amount }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Bank Commitment</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">RM {{ bank.bank_commitment }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Interest Rate</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">{{ bank.interest_rate_percent }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Loan Term in Years</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">{{ bank.loan_years }} Years</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Tenure Years</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">{{ tenure_year }} Years</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Target Instalment (Monthly Instalment)</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">RM {{ bank.target_instalment }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Net Salary Income</b-th>
                                <b-td v-for="(bank, index) in tableData" :key="index">
                                    <b-th class="bg-dark text-white">Tenure <br>Year</b-th>
                                    <b-th class="bg-dark text-white">Net Income</b-th>
                                    <b-tr v-for="(estimated_salary, index) in bank.details" :key="index">
                                        <b-td class="text-right">{{ index + 1 }}</b-td>
                                        <b-td class="text-right">{{ estimated_salary.net_income_required | moneyFormat}}</b-td>
                                    </b-tr>
                                </b-td >
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </card>
        </b-container>
    </div>
  </template>
  
  <script>
    import { Radio, RadioGroup, RadioButton } from 'element-ui'
    import axios from 'axios'
    import {apiDomain} from '@/assets/js/config.js'
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import 'sweetalert2/dist/sweetalert2.css';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import CurrencyInput from '@/components/CurrencyInput'
  
    export default {
      mixins: [ErrorFunctionMixin],
      components: {
        ValidationObserver,
        ValidationProvider,
        CurrencyInput,
        [Radio.name]: Radio,
        [RadioGroup.name]: RadioGroup,
        [RadioButton.name]: RadioButton,
      },
      data() {
        return {
            validated: false,
            tableData: [],
            age: 30,
            living_costs: null,
            commitment_amount: null,
            hire_purchase_amount: 50000,
            hire_purchase_type: 'fixed',
            loan_margin: 90,
            interest_rate_percent: null,
            bank_commitment: 1000,
            tenure_year: 9,
            
        };
      },
      methods: {
        clear(){
          this.age = null;
          this.gross_income = null;
          this.payslip_deduction = null;
          this.commitment_amount = null;
          this.living_costs = [];
          this.dsr_amount = [];
          this.hire_purchase_amount = [];
          this.loan_margin = [];
          this.interest_rate_percent = [];
          this.bank_outstanding = 120000;
        },
        handleInput(event) {
            const inputValue = event.target.value;
            // Check if the input value is a valid float number
            if (/^\d*\.?\d*$/.test(inputValue)) {
                this.$emit('input', inputValue);
            }
        },
        createData(order = 'desc'){
            const postData = {
                age: this.age,
                commitment_amount: this.commitment_amount,
                bank_commitment: this.bank_commitment,
                hire_purchase_amount: this.hire_purchase_amount,
                hire_purchase_type: this.hire_purchase_type,
                loan_margin: this.loan_margin,
                tenure_year: this.tenure_year,
            }
  
            axios.post(apiDomain + 'calculator/hire_purchase_income_eligible_calculator', postData, { headers: {"access-control-allow-origin": "*"} })
            .then(response => {
              this.tableData = response.data,
              this.$notify({
                message:
                  '<b>Data Successfully Created.',
                timeout: 5000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
            })
            .catch ( error => {
                this.errorFunction(error, "Error In Calculation") 
            });
          }  
      }
    }
  </script>
  <style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }
  table,th,td {
      border: 1px solid black;
  }
  th, td {
      padding: 3px;
  }
  .row {
      margin-left:-5px;
      margin-right:-5px;
  }
  .column {
      float: left;
      width: 20%;
      padding: 5px;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 50%;
    /* border: 1px solid #ddd; */
    align-content: center;
  }
  </style>