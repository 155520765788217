<template>
  <b-form-input
    ref="inputRef"
    type="text"
    :class="className"
    :placeholder="inputPlaceholder"
  />
</template>

<script>
import { watch } from '@vue/composition-api'
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  props: {
    value:  [String, Number],
    options: Object,
    className: String,
    inputPlaceholder: {
      type: String,
      default: '0.00'
    }
  },
  setup(props) {
    const { inputRef, setOptions, setValue } = useCurrencyInput(props.options)

    watch(
      () => props.value,
      (value) => {
        // Convert value to number if it's a string
        if (typeof value === 'string') {
          setValue(parseFloat(value));
        } else {
          setValue(value);
        }
      }
    )

    watch(
      () => props.options,
      (options) => {
        setOptions(options)
      }
    )

    return { inputRef }
  }
}
</script>