<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Calculator</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <card>
        <h3 slot="header">Credit Loan Calculator</h3>
        <form>
          <!-- Input groups with icon -->
          <b-row>
            <b-col md="4">
              <base-input type="number" label="Debt Amount *" rules="required" name="Debt Amount" placeholder="Debt Amount" v-model="debt_amount"></base-input>
            </b-col>
            <b-col md="4">
              <base-input type="number" label="Tenure Year *" rules="required" name="Tenure Year" placeholder="Tenure Year" v-model="tenure_year"></base-input>
            </b-col>
            <!-- <b-col md="4">
              <base-input type="number" label="Credit Loan" name="Credit Loan" placeholder="Credit Loan" v-model="credit_loan"></base-input>
            </b-col> -->
            <b-col>
              <h5 class="text-danger mt-5">
                <i>Column with * must fill in with value.</i>
              </h5>
            </b-col>
            
          </b-row>
          &nbsp;&nbsp;<base-button type="primary" @click="createData()">Submit</base-button>
          <base-button type="primary" @click="clear()">Clear</base-button>
        </form>
        <br>
        <b-col>
          <b-row align-v="center" class="justify-content-center">
            <table v-if="tableData">
                <tr>
                    <th>Column Name</th>
                    <th>Value (RM)</th>
                </tr>
                <!-- <tr v-for="(value, column_name) in tableData" :key="column_name">
                    <td v-if="column_name != 'Month of Advance Installment'">{{column_name}}</td>
                    <td v-if="column_name != 'Month of Advance Installment'">{{value | moneyFormat}}</td>
                </tr>
                <br><br> -->
                <tr>
                  <td>Debt Amount</td>
                  <td>{{ tableData["Debt Amount"] | moneyFormat }}</td>
                </tr>
                <tr>
                  <td>Tenure Year</td>
                  <td>{{ tableData["Tenure Year"]}}</td>
                </tr>
                <tr>
                  <td>Credit Loan</td>
                  <td>{{ tableData["Credit Loan"] | moneyFormat }}</td>
                </tr>
                <tr>
                  <td>Actual Installment</td>
                  <td>{{ tableData["Actual Installment"] | moneyFormat }}</td>
                </tr>
                <tr>
                  <td>LHDN Stamp Duty</td>
                  <td>{{ tableData["LHDN Stamp Duty"] | moneyFormat }}</td>
                </tr>
                <tr>
                  <td>Legal Fees</td>
                  <td>{{ tableData["Legal Fees"] | moneyFormat }}</td>
                </tr>
                <tr>
                  <td>Collaboration Fees (10 + 6% SST)</td>
                  <td>{{ tableData["Arrangement Fees (10 + 6% SST)"] | moneyFormat }}</td>
                </tr>
                <tr>
                  <td>Cash Out or Top Up</td>
                  <td>{{ tableData["Cash Out or Top Up"] | moneyFormat }}</td>
                </tr>
                <tr v-if="tableData['Month of Advance Installment'] == 1">
                  <td>Security Deposit ( 1 month)</td>
                  <td>{{ tableData["Advance Installment (1 month)"] | moneyFormat }}</td>
                </tr>
                <tr v-else-if="tableData['Month of Advance Installment'] == 2">
                  <td>Security Deposit ( 2 month)</td>
                  <td>{{ tableData["Advance Installment (2 month)"] | moneyFormat }}</td>
                </tr>
                <tr v-else-if="tableData['Month of Advance Installment'] == 3">
                  <td>Security Deposit ( 3 month)</td>
                  <td>{{ tableData["Advance Installment (3 month)"] | moneyFormat }}</td>
                </tr>
                <tr>
                  <td>Final Balance Amount</td>
                  <td>{{ tableData["Final Balance Amount"] | moneyFormat }}</td>
                </tr>
            </table>
          
            <table v-if="credit_loan != null && credit_loan != ''">
                <tr>
                    <!-- <th>Column Name</th> -->
                    <th>Value (RM)</th>
                </tr>
                <tr v-for="(value, column_name) in tableData1" :key="column_name">
                    <!-- <td>{{column_name}}</td> -->
                    <td>{{value}}</td>
                </tr>
            </table>
          </b-row>
        </b-col>
      </card>
      
      
    </b-container>
  </div>
</template>

<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import { BasePagination } from '@/components';
  import clientPaginationMixin from '@/assets/js/clientPaginationMixin'
  import debounce from 'lodash.debounce';

  export default {
    name: 'frequency-table',
    mixins: [ErrorFunctionMixin,clientPaginationMixin],
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        tableData: [],
        tableData1: [],
        debt_amount: null,
        tenure_year: null,
        credit_loan: null,
        debouncedHandler: ''
      };
    },
    methods: {
      clear(){
        this.debt_amount = null;
        this.tenure_year = null;
        this.credit_loan = null;
        this.tableData = [];
        this.tableData1 = [];
      },
      createData(){
        if(!this.debt_amount || !this.tenure_year)
        {
          if(!this.debt_amount && !this.tenure_year)
          {
            this.redErrorNotify( '<b>Invalid Input : <br>Debt Amount & Tenure Year is Mandatory. <br>Debt Amount must not exceed one million. </b>')
          }
          else if(!this.debt_amount || this.debt_amount > 1000000)
          {
            this.redErrorNotify( '<b>Invalid Input : <br>Debt Amount is Mandatory. <br> Debt Amount must not exceed one million. </b>')
          }
          else if(!this.tenure_year)
          {
            this.redErrorNotify( '<b>Invalid Input : <br>Tenure Year is Mandatory. <br>Tenure Year must between 1-5 years. </b>')
          }
        }
        else if(this.debt_amount < 1000)
        {
          this.redErrorNotify( '<b>Invalid Input : <br>Debt Amount must not less than RM 1,000. </b>')
        }
        else if(this.debt_amount > 1000000)
        {
          this.redErrorNotify( '<b>Invalid Input : <br> Debt Amount must not exceed one million. </b>')
        }
        else
        {
          const postData = {
            debt_amount: this.debt_amount,
            tenure_year: this.tenure_year,
            // credit_loan: this.credit_loan,
          }
          
          axios.post(apiDomain + 'calculator/credit_loan_calculator', postData, { headers: {"access-control-allow-origin": "*"} })
          .then(response => {
            // console.log(response.data);
            this.tableData = response.data
            this.$notify({
              message:
                '<b>Data Successfully Created.',
              timeout: 5000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          })
          .catch ( error => {
              // this.redErrorNotify( error.response.data);
              this.errorFunction(error, "Error In Calculation") 
          });

          this.tableData1 = [];

          if(this.credit_loan != null && this.credit_loan != '')
          {
            const postData1 = {
              debt_amount: this.debt_amount,
              tenure_year: this.tenure_year,
              credit_loan: this.credit_loan,
            }

            axios.post(apiDomain + 'calculator/credit_loan_calculator', postData1, { headers: {"access-control-allow-origin": "*"} })
            .then(response => {
              // console.log(response.data);
              this.tableData1 = response.data
            })
            .catch ( error => {
                // this.redErrorNotify( error.response.data);
                this.errorFunction(error, "Error In Calculation") 
            });
          }
          // const str = JSON.stringify(this.tableData, null, 2);
          // console.log(str);
        }

        
      },

    }
  }
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
table, td {
    border: 1px solid black;
}
th, td {
    padding: 10px;
}
.row {
    margin-left:-5px;
    margin-right:-5px;
}
.column {
    float: left;
    width: 20%;
    padding: 5px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 50%;
  border: 1px solid #ddd;
  align-content: center;
}
</style>