<template>
  <div>    
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Calculator</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
      <!-- Card stats -->

    </base-header>

    <!--Charts-->
    <b-container fluid class="mt--6">
        <card>
          <h3 slot="header">Calculator Function</h3>
          <li><a href="/calculator/creditLoanCalculator">Credit Loan Calculator</a></li>
        </card>
    </b-container>

  </div>
</template>
<script>
  import CreditLoanCalculator from './CreditLoanCalculator.vue';

  export default {
    components: {
      CreditLoanCalculator
    },
    data() {
      return {

      };
    },
    
  };
</script>
<style></style>
