<template>
    <div>
        <base-header class="pb-6">
            <b-row class="align-items-center py-4">
                <b-col lg="6" cols="7">
                    <h6 class="h2 text-white d-inline-block mb-0">Calculator</h6>
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                    <route-breadcrumb/>
                    </nav>
                </b-col>
            </b-row>
        </base-header>
  
        <b-container fluid class="mt--6">
            <card>
                <h3 slot="header">Refinance Loan Eligible Calculator</h3>
                <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                    <form @submit.prevent="handleSubmit(createData)" class="needs-validation">
                        <!-- Input groups with icon -->
                        <b-row>
                            <b-col md="2">
                                <base-input type="number" label="Age *" name="Age" rules="required" placeholder="Age" v-model="age"></base-input>
                            </b-col>
                            <b-col md="2">
                                <!-- <base-input type="text" label="Net Income *" name="Net Income" rules="required" placeholder="Net Income" v-model="net_income"></base-input> -->
                                <b-form-group label="Net Income *">
                                    <CurrencyInput
                                        v-model="net_income" required
                                        :options="{ currency: 'MYR', currencyDisplay: 'hidden', autoDecimalDigits: true }"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <!-- <base-input type="text" label="Property Value *" name="Property Value" placeholder="Property Value" rules="required" v-model="property_value"></base-input> -->
                                <b-form-group label="Property Value *">
                                    <CurrencyInput
                                        v-model="property_value" required
                                        :options="{ currency: 'MYR', currencyDisplay: 'hidden', autoDecimalDigits: true }"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <!-- <base-input type="text" label="Commitment Amount *" name="Commitment Amount" rules="required" placeholder="Commitment Amount" v-model="commitment_amount"></base-input> -->
                                <b-form-group label="Commitment Amount *">
                                    <CurrencyInput
                                        v-model="commitment_amount" required
                                        :options="{ currency: 'MYR', currencyDisplay: 'hidden', autoDecimalDigits: true }"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <!-- <base-input type="text" label="Bank Outstanding Amount *" name="Bank Outstanding Amount" rules="required" placeholder="Bank Outstanding Amount" v-model="bank_outstanding"></base-input> -->
                                <b-form-group label="Bank Outstanding Amount *">
                                    <CurrencyInput
                                        v-model="bank_outstanding" required
                                        :options="{ currency: 'MYR', currencyDisplay: 'hidden', autoDecimalDigits: true }"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right">
                                <base-button type="primary" native-type="submit">Submit</base-button>
                                <base-button type="primary" @click="clear()">Clear</base-button>
                            </b-col>
                        </b-row>
                    </form>
                </validation-observer>
                <b-col md="3">
                    <h5 class="mt-4 text-danger">
                        <i>Column with * must fill in with value.</i>
                    </h5>
                </b-col>
                <br>
                <b-col cols="12" v-if="Object.keys(tableData).length > 0">
                   <b-table-simple responsive bordered class="calculator-table">
                        <b-thead>
                            <b-tr>
                                <b-th class="bg-dark text-white">Bank Name</b-th>
                                <b-th class="bg-dark text-white" v-for="(bank, index) in tableData" :key="index">{{ bank.bank_name }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th class="bg-dark text-white">Property Value</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">RM {{ bank.property_value }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Target MOF (Loan Margin)</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">{{ bank.loan_margin | moneyFormat }}%</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Target Loan Amount</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">RM {{ bank.loan_amount }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Commitment</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">RM {{ bank.commitment_amount }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Bank Outstanding</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">RM {{ bank.bank_outstanding }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Cashout</b-th>
                                <b-td :class="getBackgroundColor(bank.cashout_amount, bank.loan_margin)" v-for="(bank, index) in tableData" :key="index">
                                    RM {{ bank.cashout_amount | moneyFormat }}
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Interest Rate</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">{{ bank.interest_rate_percent }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Loan Term in Years</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">{{ bank.loan_years }} Years</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Target Instalment (Monthly Instalment)</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">RM {{ bank.target_instalment }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Bank Outstanding Instalment</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">RM {{ bank.bank_outstanding_instalment }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Cashout Instalment</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">RM {{ bank.cashout_instalment }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="bg-dark text-white">Total Instalment (Bank Outstanding + Cashout)</b-th>
                                <b-td class="text-right" v-for="(bank, index) in tableData" :key="index">RM {{ bank.total_instalment }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </card>
        </b-container>
    </div>
  </template>
  
  <script>
    import { Radio, RadioGroup, RadioButton } from 'element-ui'
    import axios from 'axios'
    import {apiDomain} from '@/assets/js/config.js'
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import 'sweetalert2/dist/sweetalert2.css';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import CurrencyInput from '@/components/CurrencyInput'
  
    export default {
      mixins: [ErrorFunctionMixin],
      components: {
        ValidationObserver,
        ValidationProvider,
        CurrencyInput,
        [Radio.name]: Radio,
        [RadioGroup.name]: RadioGroup,
        [RadioButton.name]: RadioButton,
      },
      data() {
        return {
            validated: false,
            tableData: [],
            age: 30,
            net_income: null,
            living_costs: null,
            commitment_amount: null,
            property_value: 500000,
            loan_margin: 90,
            interest_rate_percent: null,
            bank_outstanding: 200000,
        };
      },
      methods: {
        clear(){
          this.age = null;
          this.gross_income = null;
          this.payslip_deduction = null;
          this.net_income = null;
          this.commitment_amount = null;
          this.property_value = null;
          this.living_costs = [];
          this.dsr_amount = [];
          this.hire_purchase_amount = [];
          this.loan_margin = [];
          this.interest_rate_percent = [];
          this.bank_outstanding = null;
        },
        handleInput(event) {
            const inputValue = event.target.value;
            // Check if the input value is a valid float number
            if (/^\d*\.?\d*$/.test(inputValue)) {
                this.$emit('input', inputValue);
            }
        },
        createData(order = 'desc'){
            const postData = {
                age: this.age,
                net_income: this.net_income,
                property_value: this.property_value,
                commitment_amount: this.commitment_amount,
                bank_outstanding: this.bank_outstanding,
            }
  
            axios.post(apiDomain + 'calculator/refinance_loan_eligible_calculator', postData, { headers: {"access-control-allow-origin": "*"} })
            .then(response => {
              this.tableData = response.data,
              this.$notify({
                message:
                  '<b>Data Successfully Created.',
                timeout: 5000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
            })
            .catch ( error => {
                this.errorFunction(error, "Error In Calculation") 
            });
        },
        getBackgroundColor(cashout_amount, loan_margin)
        {
            if(cashout_amount > 0 && loan_margin < 100)
            {
                return 'bg-green text-right';
            }
            else
            {
                return 'text-right';
            }
        }  
      }
    }
  </script>
  <style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }
  table,th,td {
      border: 1px solid black;
  }
  th, td {
      padding: 3px;
  }
  .row {
      margin-left:-5px;
      margin-right:-5px;
  }
  .column {
      float: left;
      width: 20%;
      padding: 5px;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 50%;
    /* border: 1px solid #ddd; */
    align-content: center;
  }
  </style>