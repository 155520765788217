<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">User</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">New User</h3>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="4">
                    <base-input label="Username" placeholder="Your name" v-model="user.user_name"></base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input label="Email" placeholder="Email" v-model="user.email"></base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input label="Role">
                      <el-select v-model="selects.simple" filterable
                                  placeholder="Select Role">
                        <el-option v-for="option in selectOptions"
                                    :key="option.role_id"
                                    :label="option.role_name"
                                    :value="option.role_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <base-input label="Password" placeholder="Password" type="password" v-model="user.password"></base-input>
                  </b-col>
                  <b-col lg="6">
                    <base-input label="Password Confirmation"
                      name="password_confirmation"
                      type="password"
                      placeholder="Password Confirmation"
                      v-model="user.password_confirmation">
                    </base-input>
                  </b-col>
                </b-row>

                <base-button type="primary" @click="createData()">Create / Save</base-button>
                <base-button type="primary" @click="$router.back()">Cancel</base-button>

              </form>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import { Select , Option } from 'element-ui'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        user: {
          user_name: null,
          email: null,
          password: null,
          password_confirmation: null,
        },
        selects:{
          simple:[]
        },
        selectOptions: []
      }
    },
    created() {
      this.roleList()
    },
    methods: {
      createData(){
          const postData = {
            name : this.user.user_name,
            password : this.user.password,
            password_confirmation: this.user.password_confirmation,
            email : this.user.email,
            active_status : 1,
            role_id : this.selects.simple
          }

          axios.post(apiDomain + 'setup/user', postData ,{ headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.$notify({
                message:
                  '<b>New User : ' + response.data.data[0].name + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
              this.$router.back()


            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Add User")
              
          });

        
      },
      roleList(){
        axios.get(apiDomain + 'setup/role', { headers: getHeader() })
        .then(response => {
          this.selectOptions = response.data.data
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Show Role")
        });
      console.log(this.info)   
      }
    },
    // beforeDestroy(){
    //   alert('asdsad');
    // }
  }
</script>
